<template>
  <div class="animated fadeIn">
    <b-container>
      <div class="mt-3 mb-3">
        <b-link :to="{ path: '/create-payment' }">Төлбөр төлөх</b-link>
      </div>
      <hr />

      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="createLoanRequest()">
        <b-form-group
          id="input-group-payDescription"
          label="Утасны дугаар"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="form.username"
            required
            placeholder="Утасны дугаар"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Хүү"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="form.interest"
            required
            placeholder="Хүү"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Хугацаа"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="form.term"
            required
            placeholder="Хугацаа"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Дүн"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="form.amount"
            required
            placeholder="Дүн"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-payDescription"
          label="Огноо"
          label-for="input-payDescription"
        >
          <b-form-input
            id="input-payDescription"
            v-model="form.date"
            required
            placeholder="Огноо"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="success">Хадгалах</b-button>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "no-dtl",
  data: function() {
    return {
      isLoading: false,
      form: {
        username: "",
        interest: null,
        term: null,
        amount: 0,
        date: "",
      },
    };
  },
  methods: {
    createLoanRequest: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "TestWebService/create_loan_request",
          {
            data: JSON.stringify(this.$data.form),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response)
            var _data = response.body;
            if (_data.success) {
              if (_data.id > 0) {
                this.createLoan(_data.id);
              } else {
                this.showToast(
                  "Алдаа",
                  "Зээлийн хүсэлт үүсхэд алдаа гарлаа",
                  "danger"
                );
              }
            } else {
              this.showToast("Алдаа", "Алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    createLoan: function(loanRequestId) {
      this.isLoading = true;
      this.$http
        .get(this.$config.APP_URL + "CompanyWebService/create_loan", {
          params: {
            requestId: loanRequestId,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response)
            var _data = response.body;
            if (_data.success) {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
